function App() {
  return (
    <div className="main">
      <h1>Maison8</h1>
      <p>COMING SOON.</p>
    </div>
  );
}

export default App;
